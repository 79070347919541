import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  produk: [],
  produkDasboard: Array.apply(null, Array(5)).map(() => null),
  selectedProdukDasboard: null,
  selectedProduk: null,
  isShowModalProduk: false,
  isShowModalDelProduk: false,
  isShowModalLogout: false,
  toast: {
    isShow: false,
    type: '',
    msg: '',
  },
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'SET_SELECTED_PRODUK_DASHBOARD':
      return {
        ...state,
        selectedProdukDasboard: rest.data,
      }
    case 'DELETE_ROW_DASHBOARD':
      return {
        ...state,
        produkDasboard: state.produkDasboard.filter((item, index) => index !== rest.data),
        selectedProdukDasboard: null,
      }
    case 'SET_PRODUK_DASHBOARD':
      return { ...state, produkDasboard: [...rest.data] }
    case 'SET_PRODUK':
      if (!rest.data) return { ...state, produk: [] }
      const dataProduk = rest.data.map((item) => {
        return {
          ...item,
          inputUkuran: {
            panjang: 0,
            lebar: 0,
            tinggi: 0,
          },
          totalHarga: 0,
          totalUkuran: 0,
          diskon: 0,
        }
      })
      return {
        ...state,
        produk: [...dataProduk],
      }
    case 'EDIT_PRODUK':
      return {
        ...state,
        produk: state.produk.map((item, index) => {
          if (item.id === state.selectedProduk) {
            return { item, ...rest.produk }
          } else {
            return item
          }
        }),
        selectedProduk: null,
      }
    case 'IS_SHOW_MODAL_PRODUK':
      return {
        ...state,
        isShowModalProduk: rest.data,
      }
    case 'IS_SHOW_MODAL_DEL_PRODUK':
      return {
        ...state,
        isShowModalDelProduk: rest.data,
      }
    case 'SET_SELECTED_PRODUK':
      return {
        ...state,
        selectedProduk: rest.data,
      }
    case 'IS_SHOW_MODAL_LOGOUT':
      return {
        ...state,
        isShowModalLogout: rest.data,
      }
    default:
      return state
  }
}

const store = createStore(changeState, applyMiddleware(thunk))
export default store
